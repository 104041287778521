var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"absolute":"","top":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',[_vm._v(" fa-plus ")])],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Filter"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.VenueFind,"mobile-breakpoint":"0","sort-by":"address.postalCode","search":_vm.filter,"items-per-page":15},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":item.name,"id":item._id,"label":"Name"},on:{"input":function (ref) {
          var value = ref.value;
          var id = ref.id;

          return _vm.savename(id, value);
}}})]}},{key:"item.address.streetAddress",fn:function(ref){
var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":(item.address || {}).streetAddress,"id":item._id,"label":"Straße"},on:{"input":function (ref) {
          var value = ref.value;
          var id = ref.id;

          return _vm.saveaddress(id, 'streetAddress', value);
}}})]}},{key:"item.address.streetNumber",fn:function(ref){
var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":(item.address || {}).streetNumber,"id":item._id,"label":"Hausnummer"},on:{"input":function (ref) {
          var value = ref.value;
          var id = ref.id;

          return _vm.saveaddress(id, 'streetNumber', value);
}}})]}},{key:"item.address.postalCode",fn:function(ref){
var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":(item.address || {}).postalCode,"id":item._id,"label":"PLZ"},on:{"input":function (ref) {
          var value = ref.value;
          var id = ref.id;

          return _vm.saveaddress(id, 'postalCode', value);
}}})]}},{key:"item.address.addressLocality",fn:function(ref){
var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":(item.address || {}).addressLocality,"id":item._id,"label":"Ort"},on:{"input":function (ref) {
          var value = ref.value;
          var id = ref.id;

          return _vm.saveaddress(id, 'addressLocality', value);
}}})]}},{key:"item.address.addressCountry",fn:function(ref){
var item = ref.item;
return [_c('base-edit-dialog-select',{attrs:{"value":(item.address || {}).addressCountry,"id":item._id,"items":[
            { value: 'de', text: 'Deutschland' },
            { value: 'at', text: 'Österreich' },
            { value: 'ch', text: 'Schweiz' }
          ],"label":"Land"},on:{"input":function (ref) {
                    var value = ref.value;
                    var id = ref.id;

                    return _vm.saveaddress(id, 'addressCountry', value);
}}})]}},{key:"item.delete",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.del(item._id)}}},[_c('v-icon',[_vm._v(" far fa-trash-alt ")])],1)]}},{key:"item.identifiers",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","fab":""},on:{"click":function($event){return _vm.openidentifiers(item)}}},[_vm._v(" "+_vm._s((item.identifiers || []).length)+" ")])]}}])})],1),_c('identifiers',{attrs:{"id":_vm.identifiersdialog.id,"identifiers":_vm.identifiersdialog.identifiers,"collection":"Venue"},model:{value:(_vm.identifiersdialog.open),callback:function ($$v) {_vm.$set(_vm.identifiersdialog, "open", $$v)},expression:"identifiersdialog.open"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }